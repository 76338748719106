<template>
  <div>
    <b-modal
        :title="$t('user-list.text.resetpassword')"
        v-model="modalShow"
        centered
        hide-footer
    >
    <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            method="POST"
            class="auth-reset-password-form mt-2"
            @submit.prevent="updateUserPassword(userSelect._id)"
          >
            <!-- password -->
            <b-form-group
              :label="$t('user-list.labels.newPassword')"
              label-for="reset-password-new"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="Password"
                :rules="whitelabelRule"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="reset-password-new"
                    v-model="password"
                    :type="password1FieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="reset-password-new"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password1ToggleIcon"
                      @click="togglePassword1Visibility()"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
<!--                <small v-if="validFormatPassword" class="text-danger">{{ validFormatPassword }}</small>-->
                
              </validation-provider>
            </b-form-group>

            <!-- confirm password -->
            <b-form-group
              label-for="reset-password-confirm"
              :label="$t('user-list.labels.confirmPassword')"
            >
              <validation-provider
                #default="{ errors }"
                name="ConfirmPassword"
                rules="required|confirmed:Password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="reset-password-confirm"
                    v-model="cPassword"
                    :type="password2FieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="reset-password-confirm"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password2ToggleIcon"
                      @click="togglePassword2Visibility()"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              block
              type="submit"
              variant="primary"
            >
              {{ $t('user-list.buttons.setNewPassword') }}
            </b-button>
          </b-form>
        </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import {
  BModal, BCard, BCardTitle, BCardText, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BFormInput, BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { whitelabelPassRules } from '@core/utils/utils'

export default {
    components: {
        BModal,
        BCard,
        BButton,
        BCardTitle,
        BCardText,
        BForm,
        BFormGroup,
        BInputGroup,
        BLink,
        BFormInput,
        BInputGroupAppend,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
        modalShow: false,
        idUserSelect: '',
        userSelect: null,

        userEmail: '',
        cPassword: '',
        password: '',
        // validation
        required,

        // Toggle Password
        password1FieldType: 'password',
        password2FieldType: 'password',
        }
    },
    watch: {
        modalShow(val) {
            if (val) {
              this.cPassword = ''
              this.password = ''
              // this.fethcUser()
            }
        },
    },
    computed: {
        password1ToggleIcon() {
        return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
        password2ToggleIcon() {
        return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
        validFormatPassword() {
          let regex = /(?=(.*[0-9]))(?=.*[!@#$%^&*()\\[\]{}\-_+=|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}/
          if(!regex.test(this.password) && this.password.length >= 8 ) return this.$t("labels.password_format")
          else return false
        },
        whitelabelRule(){
            const whitelabelSelected = this.$store.state.whitelabelCurrencyNabvar.whitelabel;
            return whitelabelPassRules(whitelabelSelected)
        }
    },
    methods: {
        showModal({_id}) {
            this.modalShow = true
            this.idUserSelect = _id
            this.userSelect =  { _id : this.idUserSelect}
        },
        hideModal() {
            this.clearForm()
            this.modalShow = false
        },
        clearForm() {
          this.password1FieldType = 'password'
          this.password2FieldType = 'password'
        },
       async fethcUser() {
           try{
               const { data } = await store.dispatch('app-user/fetchUser', { id: this.idUserSelect })
               this.userSelect = data
           }catch(error){
               console.log(error)
            }
        },

        togglePassword1Visibility() {
            this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
        },
        togglePassword2Visibility() {
            this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
        },
        async updateUserPassword() {
            try{
              this.$refs.simpleRules.validate().then(async success => {
                if (success || this.validFormatPassword.length > 0 ) {
                  return false
                }
              })

              await store.dispatch('app-user/updateUserPassword', { id: this.userSelect._id, password: this.password, confirmPassword: this.cPassword})
              this.$toast({
                  component: ToastificationContent,
                  props: {
                  title: this.$t('user-list.messages.passwordUpdated'),
                  icon: 'EditIcon',
                  variant: 'success',
                  },
              })
              this.hideModal()

            }catch(error){
              if (error.response?.data?.message?.errors.length > 0) {
                error.response?.data?.message?.errors.forEach((err) => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: err.message,
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })
                })
              }else{
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t('messages.unknown_error'),
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
              
            }
        },
    },

}
</script>